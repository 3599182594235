import { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PlantElement } from '@/app/providers/router/ui/plant-element';
import { useRoutes } from '@/entities/navigation';
import { UserRole } from '@/entities/user';
import { AgreementsSettingsPage } from '@/pages/agreements-settings';
import { AlarmDashboardPage } from '@/pages/alarm-dashboard';
import { AlarmHistoryPage } from '@/pages/alarm-history';
import { AlarmManagementPage } from '@/pages/alarm-management';
import { DashboardPage } from '@/pages/dashboard';
import { EditUserPage } from '@/pages/edit-user';
import { EditYachtPage } from '@/pages/edit-yacht';
import { FaqPage } from '@/pages/faq';
import { HistoricalPage } from '@/pages/historical';
import { MapPage } from '@/pages/map';
import { MultiYachtViewPage } from '@/pages/multi-yacht-view';
import { OilAnalysisPage } from '@/pages/oil-analysis';
import { PrivacyPolicyPage } from '@/pages/privacy-policy';
import { ProfilePage } from '@/pages/profile';
import { ProfileSettingsPage } from '@/pages/profile-settings';
import { StatusDashboardPage } from '@/pages/status-dashboard';
import { StatusHistoryPage } from '@/pages/status-history';
import { StatusRealTimePage } from '@/pages/status-real-time';
import { TermsOfServicePage } from '@/pages/terms-of-service';
import { UserCreationPage } from '@/pages/user-creation';
import { UsersManagementPage } from '@/pages/users-management';
import { VirtualCockpitPage } from '@/pages/virtual-cockpit';
import { YachtTrackPage } from '@/pages/yacht-track';
import { YachtViewAreaPage } from '@/pages/yacht-view-area';
import { YachtsFleetPage } from '@/pages/yachts-fleet';
import { AppRoutes } from '@/shared/constants/app-routes.constant';
import { SUPPORT_LINK } from '@/shared/constants/support-link.constant';
import { PageLoader } from '@/widgets/page';

import { HomeDefault } from '../home-default';
import { MobileElement } from '../mobile-element';
import { Redirect } from '../redirect';
import { RoleElement } from '../role-element';

const RouterComponent = () => {
	const { multiView, singleView, management, editManagement } = useRoutes();

	useEffect(() => {
		const zendeskIframe = document.querySelector("iframe[id*='launcher']");

		if (zendeskIframe) {
			zendeskIframe.classList.add('authenticated');
		}
	}, []);

	useEffect(() => {
		const updateIframeSize = () => {
			const zendeskIframe = document.querySelector("iframe[id*='launcher']") as HTMLIFrameElement;

			if (!zendeskIframe) return;

			const iframeDocument = zendeskIframe.contentDocument || zendeskIframe.contentWindow?.document;

			if (iframeDocument) {
				const launcherElement = iframeDocument.querySelector('[data-testid="launcher"]') as HTMLElement;

				if (launcherElement) {
					zendeskIframe.style.width = `${launcherElement.offsetWidth}px`;
				}
			}
		};

		updateIframeSize();

		window.addEventListener('resize', updateIframeSize);

		return () => window.removeEventListener('resize', updateIframeSize);
	}, []);

	return (
		<Suspense fallback={<PageLoader />}>
			<Routes>
				<Route element={<HomeDefault />} path={AppRoutes.Home} />
				<Route element={<DashboardPage />} path={AppRoutes.Dashboard} />
				<Route element={<MapPage />} path={AppRoutes.Map} />
				<Route element={<HistoricalPage />} path={AppRoutes.HistoricalData} />
				<Route element={<VirtualCockpitPage />} path={AppRoutes.VirtualCockpit} />
				<Route element={<OilAnalysisPage />} path={AppRoutes.OilAnalysis} />
				<Route
					element={
						<RoleElement roles={[UserRole.Admin, UserRole.MultiBoat]}>
							<MultiYachtViewPage />
						</RoleElement>
					}
					path={multiView}
				/>
				<Route element={<YachtViewAreaPage />} path={singleView} />
				<Route
					element={
						<PlantElement>
							<YachtTrackPage />
						</PlantElement>
					}
					path={AppRoutes.YachtTrack}
				/>
				<Route element={<PrivacyPolicyPage />} path={AppRoutes.Privacy} />
				<Route element={<TermsOfServicePage />} path={AppRoutes.TermsOfService} />
				<Route element={<Redirect externalLink={SUPPORT_LINK} />} path={AppRoutes.Support} />
				<Route element={<Redirect externalLink={SUPPORT_LINK} />} path={AppRoutes.HelpCenter} />
				<Route element={<TermsOfServicePage />} path={AppRoutes.TermsOfService} />
				<Route element={<ProfilePage />} path={AppRoutes.Profile} />
				<Route
					element={
						<MobileElement>
							<ProfileSettingsPage />
						</MobileElement>
					}
					path={AppRoutes.Settings}
				/>
				<Route
					element={
						<RoleElement>
							<UsersManagementPage />
						</RoleElement>
					}
					path={AppRoutes.UsersManagement}
				/>
				<Route
					element={
						<RoleElement roles={[UserRole.Admin]}>
							<AgreementsSettingsPage />
						</RoleElement>
					}
					path={AppRoutes.AgreementsSettings}
				/>
				<Route
					element={
						<RoleElement>
							<EditUserPage />
						</RoleElement>
					}
					path={AppRoutes.EditUser}
				/>
				<Route
					element={
						<RoleElement>
							<UserCreationPage />
						</RoleElement>
					}
					path={AppRoutes.UserCreation}
				/>
				<Route
					element={
						<RoleElement>
							<YachtsFleetPage />
						</RoleElement>
					}
					path={management}
				/>
				<Route
					element={
						<RoleElement>
							<EditYachtPage />
						</RoleElement>
					}
					path={editManagement}
				/>
				<Route element={<TermsOfServicePage />} path={AppRoutes.TermsOfService} />
				<Route element={<AlarmHistoryPage />} path={AppRoutes.AlarmHistory} />
				<Route element={<AlarmDashboardPage />} path={AppRoutes.AlarmDashboard} />
				<Route element={<AlarmManagementPage />} path={AppRoutes.AlarmManagement} />
				<Route element={<StatusRealTimePage />} path={AppRoutes.StatusRealTime} />
				<Route element={<StatusHistoryPage />} path={AppRoutes.StatusHistory} />
				<Route element={<StatusDashboardPage />} path={AppRoutes.StatusDashboard} />
				<Route
					element={
						<MobileElement>
							<FaqPage />
						</MobileElement>
					}
					path={AppRoutes.Faq}
				/>
				<Route element={<TermsOfServicePage />} path={AppRoutes.FaqTermsOfService} />
				<Route element={<PrivacyPolicyPage />} path={AppRoutes.FaqPrivacy} />
				<Route element={<Navigate to={AppRoutes.Home} />} path="*" />
			</Routes>
		</Suspense>
	);
};

export default RouterComponent;
