export enum NoDataError {
	LocationNotFound = 'Location variables for the boat not found',
	HistogramNotFound = 'Histogram data not found for given period of time',
	AccountDeleted = 'Your account no longer exists',
}

export enum NoDataErrorWithoutDetail {
	UserNotFound = 'User with username',
}

export const NoDataErrorTranslation: Record<
	NoDataError,
	'toast.error.noDataLocationError' | 'toast.error.noDataHistogramError' | 'toast.error.accountDeletedError'
> = {
	[NoDataError.LocationNotFound]: 'toast.error.noDataLocationError',
	[NoDataError.HistogramNotFound]: 'toast.error.noDataHistogramError',
	[NoDataError.AccountDeleted]: 'toast.error.accountDeletedError',
};
