import styled from 'styled-components';

import { TextProps } from './text.types';

export const Text = styled.p<
	Required<Pick<TextProps, 'size' | 'weight' | 'color' | 'cursor' | 'align' | 'paragraph' | 'wordBreak'>>
>`
	margin: 0;
	padding: 0;
	cursor: ${({ cursor }) => cursor};
	font-weight: ${({ weight }) => weight};
	text-align: ${({ align }) => align};
	color: ${({ color, theme: { text } }) => text.colors[color]};
	font-size: ${({ size, theme: { text } }) => text.size[size]};
	margin-bottom: ${({ paragraph }) => paragraph};
	word-break: ${({ wordBreak }) => wordBreak};
	white-space: pre-line;
`;
