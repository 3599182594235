import { BoatConnectionStatus } from '@/shared/constants/boat-connection-status.constant';
import { capitalizeWord } from '@/shared/lib/helpers/capitalize-word.helper';
import { convertTimezone } from '@/shared/lib/helpers/convert-timezone.helper';
import { dateFormat } from '@/shared/lib/helpers/date-format.helper';
import { roundOffPosition } from '@/shared/lib/helpers/round-off-position.helper';

import { BoatGetLocationsResponse, BoatLocation } from '../types/boat-map-api.interface';

const ConnectionStatusLabel = {
	[BoatConnectionStatus.Online]: 'Online',
	[BoatConnectionStatus.Offline]: 'Offline',
	[BoatConnectionStatus.Warning]: 'Warning',
};

export const parseBoatLocation = (boat: BoatGetLocationsResponse, timezone: string): BoatLocation => ({
	id: String(boat.id),
	status:
		boat.connection_status === BoatConnectionStatus.Offline
			? ConnectionStatusLabel[boat.connection_status]
			: capitalizeWord(boat.status) ?? ConnectionStatusLabel[boat.connection_status],
	mmsi: boat.mmsi,
	position: boat?.latitude && boat?.longitude ? [Number(boat.latitude), Number(boat.longitude)] : undefined,
	tablePosition:
		boat?.latitude && boat?.longitude
			? [roundOffPosition(boat.latitude, ['latitude']), roundOffPosition(boat.longitude, ['longitude'])]
			: ['Not available', 'Not available'],
	boatUuid: boat.boat_uuid ?? 'Not available',
	originalTs: boat.original_ts ? dateFormat(convertTimezone(boat.original_ts, timezone)) : 'Not available',
	lastUpdate: boat.original_ts ?? null,
	connectionStatus: boat.connection_status,
	speed: boat.speed_over_ground?.toFixed(1),
});
