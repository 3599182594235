import * as BREAKPOINTS from '../../styles/breakpoints';
import * as COLOR from './colors';

export const plantTheme = {
	colors: {
		error: COLOR.ERROR,
		warning: COLOR.WARNING,
		success: COLOR.SUCCESS,
		white: COLOR.WHITE,
		alert: COLOR.ORANGE_1,
		monitor: COLOR.BLUE_1,
	},
	page: {
		height: 'calc(100vh - 58px)',
	},
	text: {
		size: {
			l: '20px',
			m: '17px',
			s: '15px',
			xs: '12px',
		},
	},
	button: {
		size: {
			full: '100%',
			l: '656px',
			m: '320px',
			s: '200px',
			xs: '120px',
		},
	},
	input: {
		width: {
			full: '100%',
			l: '656px',
			xm: '380px',
			m: '320px',
			s: '235px',
		},
		minWidth: {
			full: '100%',
			l: '200px',
			m: '170px',
			s: '130px',
		},
	},
	textarea: {
		width: {
			full: '100%',
			l: '990px',
			m: '540px',
			s: '320px',
		},
	},
	title: {
		h1: {
			fontSize: '40px',
			fontWeight: 600,
		},
		h2: {
			fontSize: '32px',
			fontWeight: 600,
		},
		h3: {
			fontSize: '24px',
			fontWeight: 600,
		},
		h4: {
			fontSize: '20px',
			fontWeight: 600,
		},
		h5: {
			fontSize: '18px',
			fontWeight: 600,
		},
		h6: {
			fontSize: '16px',
			fontWeight: 600,
		},
	},
	autocomplete: {
		width: {
			full: '100%',
			l: '356px',
			m: '320px',
			s: '260px',
		},
	},
	datepicker: {
		border: {
			s: '4px',
		},
	},
	avatar: {
		size: {
			small: '32px',
			medium: '48px',
		},
	},
	layouts: {
		sidebar: {
			width: '234px',
			collapsed: {
				width: '99px',
			},
		},
		header: {
			height: '58px',
		},
	},
	tabs: {
		padding: {
			medium: '24px',
			small: '16px',
		},
	},
	table: {
		fontSize: '17px',
		head: {
			fontWeight: 600,
		},
		body: {
			fontWeight: 400,
		},
	},
	tag: {
		fontSize: '15px',
	},
	zIndex: {
		select: 10,
		selectIcon: 11,
		map: 1,
		fullScreenMap: 5000,
		datePicker: 1000,
		windyMap: 1000,
		header: 1200,
		menu: 1500,
		toast: 20000,
		tooltip: 2000,
		sidebar: 3000,
		modal: 10000,
	},
	transition: {
		primary: '0.2s ease-in-out',
	},
	breakpoints: {
		mobile: [BREAKPOINTS.MOBILE_S, BREAKPOINTS.MOBILE_M, BREAKPOINTS.MOBILE_L],
		tablet: [BREAKPOINTS.TABLET],
		laptop: [BREAKPOINTS.LAPTOP, BREAKPOINTS.LAPTOP_L],
		desktop: [BREAKPOINTS.DESKTOP],
	},
	media: {
		mobile: `(max-width: ${BREAKPOINTS.MOBILE_L}px)`,
		mobileAndTablet: `(min-width: ${BREAKPOINTS.MOBILE_L}px) and (max-width: ${BREAKPOINTS.TABLET}px)`,
		mobileAndLaptop: `(max-width: ${BREAKPOINTS.LAPTOP}px)`,
		tablet: `(min-width: ${BREAKPOINTS.TABLET}px) and (max-width: ${BREAKPOINTS.LAPTOP}px)`,
		tabletAndMobile: `(max-width: ${BREAKPOINTS.TABLET}px)`,
		tabletAndLaptop: `(min-width: ${BREAKPOINTS.MOBILE_L}px) and (max-width: ${BREAKPOINTS.LAPTOP}px)`,
		desktop: `(min-width: ${BREAKPOINTS.LAPTOP}px) and (max-width: ${BREAKPOINTS.DESKTOP}px)`,
		desktopAndMobile: `(max-width: ${BREAKPOINTS.LAPTOP_L}px)`,
		desktopAndTablet: `(min-width: ${BREAKPOINTS.MOBILE_L}px) and (max-width: ${BREAKPOINTS.LAPTOP_L}px)`,
	},
	spinner: {
		size: {
			l: '80px',
			m: '60px',
			s: '30px',
		},
		border: {
			l: '6px',
			m: '4px',
			s: '2px',
		},
	},
	windy: {
		icon: {
			radius: '50%',
		},
		buttons: {
			radius: '25px',
		},
	},
};
